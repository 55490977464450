import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Container, Form, Button } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { ChevronLeft, ChevronRight } from "react-feather";
import BlogCard from './BlogCard';
import BlogCardFullWidth from './BlogCardFullWidth';
import Url from '../Url';
import { Helmet } from "react-helmet";

const BlogListing = () => {
  const url1 = Url();
  const url = url1["url"];
  const [blogList, setBlogList] = useState([]);
  const [toptItem, setTopItem] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [categoryFilter, setCategoryFilter] = useState("");


  function getUniqueCategories() {
    const categories = [...new Set(blogList.map((item) => item.category_name))];
    return categories;
  }

  const uniqueCategories = getUniqueCategories();

  useEffect(() => {
    fetchItems(currentPage);
  }, [currentPage]);

  const fetchItems = async (page) => {
    try {
      const api = url + "getAllActiveContents" + "?page=" + page;

      fetch(api, {
        method: 'GET'
      })
        .then((apidata) => {
          return apidata.json();
        })
        .then((actualdata) => {
          setBlogList(actualdata.contentData.data);
          setTopItem(actualdata.contentData.data[0]);
          setLastPage(actualdata.contentData.last_page);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= lastPage) {
      setCurrentPage(newPage);
      window.scrollTo(0, 0);
    }
  };

  const filteredBlogList = blogList.filter((item) =>
    item.category_name.toLowerCase().includes(categoryFilter.toLowerCase())
  );




  return (
    <Fragment>
      <Helmet>
        <title>{"Internshipgate - Blogs"}</title>
        <meta
          name="keywords"
          content={"Internship, internships in India, internships in Bangalore, internships in Pune"}
        />
        <meta
          name="description"
          content={
            "Internshipgate is a leading platform to search for internships in India, Bangalore, Pune"
          }
        />
      </Helmet>

      <div className="pb-8 bg-white">
        <Container>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} key={toptItem.id}>
              <BlogCardFullWidth item={toptItem} />
            </Col>

            <Form.Group>
              <div style={{ display: 'flex' }}>
                <input
                  type="text"
                  placeholder="Search the blog by category"
                  value={categoryFilter}
                  onChange={(e) => setCategoryFilter(e.target.value)}
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    padding: '5px',
                    width: '30%',
                    margin: '2rem 2rem 2rem 0rem'
                  }}
                />
                <select placeholder='search the items'
                  value={categoryFilter}
                  onChange={(e) => setCategoryFilter(e.target.value)}
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    padding: '5px',
                    width: '30%',
                    margin: '2rem 2rem 2rem 0rem'
                  }}
                >
                  <option value="">Filter by category</option>
                  {uniqueCategories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
            </Form.Group>

          </Row>
        </Container>

        {filteredBlogList.length > 0 && (
          <div className="pb-8 bg-white">
            <Container>
              <Row>
                {filteredBlogList.map((item, index) => (
                  <Col
                    xl={4}
                    lg={4}
                    md={6}
                    sm={12}
                    key={index}
                    className="d-flex"
                  >
                    <BlogCard item={item} />
                  </Col>
                ))}
              </Row>
            </Container>

            <ReactPaginate
              previousLabel={<ChevronLeft size="10px" />}
              nextLabel={<ChevronRight size="10px" />}
              pageCount={lastPage}
              onPageChange={() => handlePageChange(currentPage + 1)}
              containerClassName={"justify-content-center mb-0 pagination w-75"}
              previousLinkClassName={"page-link mx-1 rounded"}
              nextLinkClassName={"page-link mx-1 rounded"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link mx-1 rounded"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"active"}
              forcePage={currentPage - 1}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default BlogListing;
