// import node module libraries
import React, { Fragment, useMemo, useEffect,useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Button,
  Badge,
  Dropdown,
  Table,
  Form,
  Modal,
} from "react-bootstrap";
import { XCircle, MoreVertical } from "react-feather";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import Url from "components/Url";
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import ReactPaginate from "react-paginate";
import { ChevronLeft, ChevronRight } from "react-feather";

const InternshipPostApproval = () => {
  const url1 = Url();
  const s3 = url1.s3;
  const url = url1.url;
  const [load,setLoad] = useState(false);
  const [internshipPostList, setInternshipPostList] = useState([]);
  const [showInternshipPostComment, setShowInternshipPostComment] = useState(false);
  const [internshipPostComment, setInternshipPostComment] = useState();
  const [employerInternshipId,setEmployerInternshipId] = useState();

  const token = localStorage.getItem('admtoken');

  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [recordcount,setRecordCount] = useState();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const fetchData = (page) => {

    let apiURL =  url + "getCMSInternshipPostList" + (searchText && `/${searchText}`) + (currentPage ? "?page=" + currentPage: "");

    Axios.get(
        apiURL,
        {headers: {
                "Content-type": "Application/json",
                "Authorization": `Bearer ${token}`
                }   
            }
      )
      .then((response) => {
          //var response = response.data.virtualInternshipList;
          setInternshipPostList (response.data.lmsInternshipPostList.data);
          //setCurrentPage(response.data.current_page);
          // setting page count
          console.log(response.data)
          setPageCount(response.data.lmsInternshipPostList.last_page);       
          setRecordCount(response.data.lmsInternshipPostList.total);         
        },
        (error) => {
          var status = error.response.status
        }
      );	
      setLoad(false);
};	

const handleSearch = () => {
  setCurrentPage(0);
  fetchData();
};

const handleApprove = (employerInternshipId, status) => {
    let apiURL =  url + "updateEmployerInternshipStatus";
    try{
        Axios.post(
            apiURL, 
            {employerInternshipId,status:status,internshipPostComment },
            {headers: {
                    "Content-type": "Application/json",
                    "Authorization": `Bearer ${token}`
                    }   
                }
        ).then((response) => {
            console.log("in then");

            console.log(response);

            if (response.status === 200) {
                toast.success(response.data.message,{autoClose: 1500});
                setLoad(true);
            }else{
                console.log("in else");
                handleErrorResponse(response);
            }
            },(error) => {
                console.log("in error");
                toast.warning("Internship status not updated, please check",{autoClose: 1500})
            }
        );	
        
    }catch(error){
        console.log(error.response);

        if (error.response) {
            // The request was made, but the server responded with an error
            handleErrorResponse(error.response);
          } else if (error.request) {
            // The request was made, but no response was received
            console.error('No response received.');
          } else {
            // Something happened in setting up the request that triggered an error
            console.error('Error:', error.message);
          }
    }	
};

const handleErrorResponse = (response) => {
    // Check specific HTTP status codes and display the error message
    switch (response.status) {
      case 400:
        console.error('Bad Request:', response.data);
        // Display the error message to the user
        toast.warning(`Bad Request: ${response.data.message}`);
        break;
      case 403:
        console.error('Unauthorized:', response.data);
        // Display the error message to the user
        toast.warning(`Unauthorized: ${response.data.message}`);
        break;
      case 404:
        console.error('Not Found:', response.data);
        // Display the error message to the user
        toast.warning(`Not Found: ${response.data.message}`);
        break;
      default:
        console.error('Unhandled Error:', response.data);
        // Display a generic error message to the user
        toast.warning('An error occurred. Please try again later.');
    }
  };

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const columns = useMemo(
    () => [
      { accessor: "id", Header: "ID",width:150},     
      { accessor: "org_name", Header: "Employer Name",width:150},
      { accessor: "email", Header: "Employer Email",width:150},
      { accessor: "internship_details", Header: "Internship Details",
        Cell: ({ value, row }) => {

          return (
            <Col style={{width:"100px"}} className="text-wrap">
              {value}
            </Col>
          );

      },
    },
      { accessor: "posting_date", Header: "Posting Date",width:150},
      { accessor: "admin_approved_desc", Header: "Admin Approval Status",width:150,
        Cell:({value, row}) => {
            return(
            <span className={`small fw-bold ${row.original.admin_approved == 0 ? "text-muted" : row.original.admin_approved == 1 ? "text-warning" : row.original.admin_approved == 2 ? "text-success" : row.original.admin_approved == 3 ? "text-danger" : "text-secondary"}`} >
                {value}
             </span>            
            )
        },      
      
      },
      {
        accessor: "admin_approved",
        Header: "ACTION",
        width:150,
        Cell: ({ value, row }) => {
          if (value == 1) {
            return (
              <Fragment>
                <Button
                  variant="success"
                  className="btn-sm"                  
                  onClick={() => handleApprove(row.original.id, 2)}
                >
                  Approve
                </Button>{" "}
                <Button
                  onClick={() => {setEmployerInternshipId(row.original.id); setShowInternshipPostComment(true)}}
                  variant="outline"
                  className="btn-outline-danger text-black btn-sm"
                >
                  Reject
                </Button>

              </Fragment>
            );
          }
        },
      },
    ],
    []
  );

  const data = useMemo(() => internshipPostList, [internshipPostList]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  return (
    <Fragment>
      <Modal
        show={showInternshipPostComment}
        onHide={() => setShowInternshipPostComment(false) }
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
            <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
            <Modal.Body className="pb-0">
            <Form.Group className="mb-3" >
                <Form.Control
                type="text"
                onChange={(e) => setInternshipPostComment(e.target.value)}
                placeholder="Add Comment"
                className="mb-3"
                />
                <Button variant="primary" className="btn-sm" onClick={() => {setShowInternshipPostComment(false) ; handleApprove(employerInternshipId, 3)  }} >Save</Button>
                <Button variant="outline-secondary" className="btn-sm ms-2" onClick={() => {setShowInternshipPostComment(false);setEmployerInternshipId(0);}} >Cancel</Button>
            </Form.Group>        
            </Modal.Body>
        </Modal>
        <div className=" overflow-hidden">
        <Row>
          <Col lg={12} md={12} sm={12} className="px-5 ">
            <h3>Internship Posts Approval</h3>
          </Col>
        </Row>
        </div>

      <div className=" overflow-hidden">
        <Row>
          <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 d-flex">
            {/* <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder="Search by email, Project name, Student name etc"
            /> */}
            <Col sm={8}>
            <Form.Group className="mb-3" >
                <Form.Control
                type="text"
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search by email,internship details,employer name. OR search with 'all' to get all the internship list"
                className="mb-3"
                />
            </Form.Group>
            </Col>
            <Col sm={4} className="ms-2">  
              <Button variant="outline-primary" className="btn" onClick={handleSearch}>Search</Button>
            </Col>
          </Col>
        </Row>
        <Row>
            <Col sm={12}>
              Total {recordcount} Records
            </Col>
        </Row>
      </div>

      <div className="table-responsive border-0 overflow-y-hidden">
        <Table {...getTableProps()} className="text-nowrap">
          <thead className="table-light">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination @ Footer */}
      {console.log(pageCount)}
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        onPageChange={handlePageChange}
        previousLabel={<ChevronLeft size="10px" />}
        nextLabel={<ChevronRight size="10px" />}
        containerClassName={"justify-content-center mb-0 p-2 pagination"}
        previousLinkClassName={"page-link mx-1 rounded"}
        nextLinkClassName={"page-link mx-1 rounded"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link mx-1 rounded"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"active"}
      />
      <ToastContainer />
    </Fragment>
  );
};

export default InternshipPostApproval;
