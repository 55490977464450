import { Card, Image, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, Fragment } from "react";
import likeclicked from "../../assets/images/job/likeclicked.svg";
import like from "../../assets/images/job/liked.svg";
import { BsEye } from "react-icons/bs";
// import tippy tooltip
import Tippy from "@tippyjs/react";
import "tippy.js/animations/scale.css";
import Url from "components/Url";

const JobListingListviewCard = ({
  item,
  fav,
  setFav,
  Favorite,
  Apply,
  getTimePassed,
}) => {
  const url1 = Url();
  const s3 = url1["s3"];
  const url = url1["url"];
  const [s3url, setS3url] = useState(s3 + "imgupload/");

  return (
    <Card className="card-bordered mb-4 card-hover">
      <Card.Body>
        <Row>
          <Col md={1} className="mb-3 mb-md-0">
            <Image
              src={s3url + item.org_logo}
              alt="Company Logo"
              className="icon-shape border rounded-circle"
              style={{
                height: "56px",
                width: "56px",
              }}
            />
          </Col>
          <Col md={11} className="mt-3 mt-md-0">
            <div className="d-flex justify-content-between mb-3">
              <div>
                <h3 className="mb-1 fs-4">
                  <Link
                    to={`/internship-details/${item.id}`}
                    className="text-inherit"
                  >
                    {item.internship_details}
                  </Link>
                </h3>
                <p className="mb-0">
                  <span className="text-muted">at {item.org_name}</span>
                </p>
              </div>
              <div>
                <img
                  src={fav.has(item.id) ? likeclicked : like}
                  alt="Favorite"
                  style={{ cursor: "pointer" }}
                  onClick={() => Favorite(item.id)}
                />
              </div>
            </div>
            <Row className="text-muted mb-2">
              <Col xs={12} md={4} className="mb-2 mb-md-0">
                <i className="fe fe-briefcase me-1"></i>
                Duration: {item.duration1}
                {item.duration2 === "M" ? " Months" : " Weeks"}
              </Col>
              <Col xs={12} md={4} className="mb-2 mb-md-0">
                <i className="fe fe-map-pin me-1"></i>
                Location: {item.location}
              </Col>
              <Col xs={12} md={4}>
                <i className="fe fe-dollar-sign me-1"></i>
                Stipend: {item.compensation1 ? `${item.compensation1} per month` : "Not disclosed"}
              </Col>
            </Row>
            <Row className="text-muted mb-2">
              <Col xs={12} md={4} className="mb-2 mb-md-0">
                <i className="bi bi-tools me-1"></i>
                Skills: {item.skill}
              </Col>
              <Col xs={12} md={4} className="mb-2 mb-md-0">
                <i className="fe fe-clock me-1"></i>
                Posted: {getTimePassed(item.posting_date)}
              </Col>
              <Col xs={12} md={4}>
                <i className="fe fe-clock me-1"></i>
                Starts: Start Immediately
              </Col>
            </Row>
            <Row className="mt-1">
              <Col xs={12} className="d-flex justify-content-between align-items-center">
                {item.impressions > 0 && (
                  <span style={{ color: "var(--geeks-primary)" }}>
                    <BsEye size={13} />
                    <span className="small ms-1">{item.impressions} impressions</span>
                  </span>
                )}
                <div>
                  <Link to={`/internship-details/${item.id}`} className="text-inherit">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-warning me-2"
                    >
                      More details
                    </button>
                  </Link>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => Apply(item.id)}
                  >
                    Apply Now
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default JobListingListviewCard;

