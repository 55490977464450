// import node module libraries
import { useState, useEffect } from "react";
import { Card, Collapse, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import Slider from 'rc-slider';
// import 'rc-slider/assets/index.css';
//import Nouislider from "nouislider-react";
//import "nouislider/distribute/nouislider.css";
import RangeSlider from "components/elements/range-slider/RangeSlider";
import Url from "../Url";
const JobFilters = ({
  locationFilter,
  setLocationFilter,
  skillsFilter,
  setSkillsFilter,
  durationFilter,
  setDurationFilter,
  startingfilter,
  setStartingfilter,
  stipendfilter,
  setStipendfilter,
  isWFH,
  setIsWFH,
  isIFW,
  setIsIFW,
  isFullTime,
  setIsFullTime,
  isPartTime,
  setIsPartTime,
  isIWJO,
  setIsIWJO,
  isOnField,
  setIsOnFiled,
  searchAnything,
  setSearchAnything,
  lastUpdated,
  setLastUpdated,
}) => {
  const [openLocation, setOpenLocation] = useState(true);
  const [openSalary, setOpenSalary] = useState(true);
  const [openEducation, setOpenEducation] = useState(true);
  const [openLastUpdated, setOpenLastUpdated] = useState(true);
  const [allLocations, setAllLocations] = useState([]);
  const [allSkills, setAllSkills] = useState([]);
  const [showDate, setShowDate] = useState("");
  const [open, setOpen] = useState(true);
  const url1 = Url();
  const url = url1["url"];
  const convertdate = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    var converted = [date.getFullYear(), mnth, day].join("-");
    setStartingfilter(converted);
    //console.log(converted)
  };
  const clearAllFilters = () => {
    console.log("inside");
    setLocationFilter([]);
    setSkillsFilter([]);
    setDurationFilter("");
    setStartingfilter("all");
    setStipendfilter("any");
    setIsWFH(false);
    setIsIWJO(false);
    setIsOnFiled(false);
    setIsFullTime(false);
    setIsPartTime(false);
    setIsIWJO(false);
    setIsIFW(false);
    setShowDate("");
    setSearchAnything("");
  };
  useEffect(() => {
    const api1 = url + "getLocationMasterDetails";
    // console.log("calee");
    fetch(api1, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        // console.log(actualdata1, "location");
        setAllLocations(actualdata1);
      });
  }, []);
  // console.log(allLocations, "location");
  useEffect(() => {
    const api1 = url + "getSoftwareSkill";
    fetch(api1, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        setAllSkills(actualdata1);
      });

    setOpen(window.innerWidth <= 500 ? false : true);
  }, []);
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      // console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "#29baf9" : null,
        color: isFocused ? "white" : null,
      };
    },
  };
  //   console.log("open", open);

  return (
    <>
      <Card className="border mb-6 mb-md-0 shadow-none">
        <Card.Header
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
        >
          <h4 className="mb-0 fs-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-filter text-muted me-2"
              viewBox="0 0 16 16"
            >
              <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
            </svg>
            All Filters
            <span className="float-end">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="currentColor"
                className="bi bi-chevron-down"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </span>
          </h4>
        </Card.Header>
        {/* </Button> */}
        <Collapse in={open}>
          <div id="example-collapse-text">
            <Card.Body className="py-3">
              <Link className="fs-5 text-dark fw-semi-bold">Locations</Link>
              <Select
                styles={colourStyles}
                isMulti
                name="location"
                options={allLocations}
                className="mt-2 selectpicker "
                value={locationFilter}
                onChange={(e) => {
                  setLocationFilter(e);
                }}
              />
            </Card.Body>
            <Card.Body className="py-3">
              <Link className="fs-5 text-dark fw-semi-bold">Skills</Link>
              <Select
                isMulti
                styles={colourStyles}
                value={skillsFilter}
                name="location"
                options={allSkills}
                className="mt-2 "
                classNamePrefix="select namebox"
                onChange={(e) => {
                  setSkillsFilter(e);
                }}
              />
            </Card.Body>
            <Card.Body className="py-3">
              <Link className="fs-5 text-dark fw-semi-bold">Duration</Link>
              <Form.Control
                as="select"
                styles={colourStyles}
                required
                className=" mt-2   "
                value={durationFilter}
                onChange={(e) => {
                  setDurationFilter(e.target.value);
                }}
              >
                <option value="-">All</option>
                <option value="1-M">1-Month</option>
                <option value="2-M">2-Month</option>
                <option value="3-M">3-Month</option>
                <option value="4-M">4-Month</option>
                <option value="5-M">5-Month</option>
                <option value="6-M">6-Month</option>
                <option value="7-M">7-Month</option>
                <option value="8-M">8-Month</option>
                <option value="9-M">9-Month</option>
                <option value="10-M">10-Month</option>
                <option value="11-M">11-Month</option>
                <option value="12-M">12-Month</option>
                <option value="1-W">1-Week</option>
                <option value="2-W">2-Week</option>
                <option value="3-W">3-Week</option>
                <option value="4-W">4-Week</option>
                <option value="5-W">5-Week</option>
                <option value="6-W">6-Week</option>
                <option value="7-W">7-Week</option>
                <option value="8-W">8-Week</option>
                <option value="9-W">9-Week</option>
                <option value="10-W">10-Week</option>
                <option value="11-W">11-Week</option>
                <option value="12-W">12-Week</option>
              </Form.Control>
            </Card.Body>
            <Card.Body className="py-3">
              <Link className="fs-5 text-dark fw-semi-bold">Last Updated</Link>
              <Form.Control
                as="select"
                styles={colourStyles}
                required
                className=" mt-2   "
                value={lastUpdated}
                onChange={(e) => {
                  setLastUpdated(e.target.value);
                }}
              >
                <option value="all">All</option>
                <option value="Last-1-Day">Last 1 Day</option>
                <option value="Last-3-Days">Last 3 Days</option>
                <option value="Last-7-Days">Last 7 Days</option>
              </Form.Control>
            </Card.Body>
            {/* <Card.Body className="py-3">
				<Link className="fs-5 text-dark fw-semi-bold">Starting From</Link>
				<DatePicker
					className={
						"date mt-2  form_control_listing_datepicker_new custom-select w-100 p-2 border-none"
					}
					wrapperClassName="date"
					placeholderText="Choose date"
					name="startdate"
					minDate={new Date()}
					selected={showDate}
					onChange={(startingfilter) => {
						setShowDate(startingfilter);
						setStartingfilter(startingfilter);
						convertdate(startingfilter);
					}}
				/>
			</Card.Body> */}
            <Card.Body className=" py-3">
              <Link to="/" className="fs-5 text-dark fw-semi-bold">
                Stipend
              </Link>
              <Form.Control
                as="select"
                styles={colourStyles}
                required
                className=" mt-2  form_control_listing_height_new custom-select"
                value={stipendfilter}
                onChange={(e) => {
                  setStipendfilter(e.target.value);
                }}
              >
                <option value="any">Any</option>
                <option value="unpaid">Unpaid</option>
                <option value="0,2000">0-2000</option>
                <option value="2001,5000">2000-5000</option>
                <option value="5000,max">{"above "}5000</option>
              </Form.Control>
            </Card.Body>
            <Card.Body className="border-top py-2 d-flex justify-content-between">
              <Form.Check type="checkbox" className="mb-1">
                <Form.Check.Input
                  type="checkbox"
                  checked={isWFH}
                  onChange={() => {
                    setIsWFH(!isWFH);
                  }}
                />
                <Form.Check.Label>Work From Home</Form.Check.Label>
              </Form.Check>
            </Card.Body>
            <Card.Body className=" py-2 d-flex justify-content-between">
              <Form.Check type="checkbox" className="mb-1">
                <Form.Check.Input
                  type="checkbox"
                  checked={isIFW}
                  onChange={() => {
                    setIsIFW(!isIFW);
                  }}
                />
                <Form.Check.Label>Internship for Women</Form.Check.Label>
              </Form.Check>
            </Card.Body>{" "}
            <Card.Body className=" py-2 d-flex justify-content-between">
              <Form.Check type="checkbox" className="mb-1">
                <Form.Check.Input
                  type="checkbox"
                  checked={isFullTime}
                  onChange={() => {
                    setIsFullTime(!isFullTime);
                  }}
                />
                <Form.Check.Label>Full time</Form.Check.Label>
              </Form.Check>
            </Card.Body>{" "}
            <Card.Body className=" py-2 d-flex justify-content-between">
              <Form.Check type="checkbox" className="mb-1">
                <Form.Check.Input
                  type="checkbox"
                  checked={isPartTime}
                  onChange={() => {
                    setIsPartTime(!isPartTime);
                  }}
                />
                <Form.Check.Label>Part Time</Form.Check.Label>
              </Form.Check>
            </Card.Body>
            <Card.Body className="py-2 d-flex justify-content-between">
              <Form.Check type="checkbox" className="mb-1">
                <Form.Check.Input
                  type="checkbox"
                  checked={isIWJO}
                  onChange={() => {
                    setIsIWJO(!isIWJO);
                  }}
                />
                <Form.Check.Label>Internships with job offer</Form.Check.Label>
              </Form.Check>
            </Card.Body>{" "}
            <Card.Body className=" py-3 d-flex justify-content-between">
              <Form.Check type="checkbox" className="mb-2">
                <Form.Check.Input
                  type="checkbox"
                  checked={isOnField}
                  onChange={() => {
                    setIsOnFiled(!isOnField);
                  }}
                />
                <Form.Check.Label>On Field/Office</Form.Check.Label>
              </Form.Check>
            </Card.Body>
            {/* <Card.Body className="border-top py-3">
							<Link
								to="#"
								onClick={() => setOpenLastUpdated(!openLastUpdated)}
								aria-controls="last-updated"
								aria-expanded={openLastUpdated}
								className="fs-5 text-dark fw-semi-bold"
								data-bs-toggle="collapse"
							>
								Last updated
								<span className="float-end">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="14"
										height="14"
										fill="currentColor"
										className="bi bi-chevron-down"
										viewBox="0 0 16 16"
									>
										<path
											fillRule="evenodd"
											d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
										/>
									</svg>
								</span>
							</Link>
							<Collapse in={openLastUpdated}>
								<div id="last-updated">
									<div className="mt-3">
										<select
											className="selectpicker form-control"
											data-width="100%"
										>
											<option value="">Last Day</option>
											<option value="Newest">Newest</option>
											<option value="Oldest">Oldest</option>
										</select>
									</div>
								</div>
							</Collapse>
						</Card.Body> */}
            {/* <Card.Body className="border-top py-3">
				<Link
					to="#"
					onClick={() => setOpenEducation(!openEducation)}
					aria-controls="education"
					aria-expanded={openEducation}
					className="fs-5 text-dark fw-semi-bold"
					data-bs-toggle="collapse"
				>
					Education
					<span className="float-end">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="14"
							height="14"
							fill="currentColor"
							className="bi bi-chevron-down"
							viewBox="0 0 16 16"
						>
							<path
								fillRule="evenodd"
								d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
							/>
						</svg>
					</span>
				</Link>
				<Collapse in={openEducation}>
					<div id="education">
						<div className="mt-3">
							{["M.Tech", "MBA / MTech", "Any Graduate", "Post Graduate"].map(
								(item, index) => (
									<Form.Check
										type="checkbox"
										className="mb-2"
										id={index}
										key={index}
									>
										<Form.Check.Input type="checkbox" />
										<Form.Check.Label>{item}</Form.Check.Label>
									</Form.Check>
								)
							)}
						</div>
					</div>
				</Collapse>
			</Card.Body> */}
            <Card.Body className="py-3 d-grid">
              <Button
                as="a"
                variant="outline-secondary"
                href="#"
                onClick={clearAllFilters}
              >
                Clear Data
              </Button>
            </Card.Body>
          </div>
        </Collapse>
      </Card>
    </>
  );
};

export default JobFilters;
