import React, {Fragment, useState, useEffect } from "react";
import {
	Col,
	Row,
	Dropdown,
	Card,
	Breadcrumb,
	Button,
	Modal,
	Table,
    Form
} from 'react-bootstrap';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination,
	useRowSelect
} from 'react-table';
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import { Trash, Send, Inbox, MoreVertical } from 'react-feather';
import Pagination from 'components/elements/advance-table/Pagination';
import { Link } from "react-router-dom";
// import Navadmin from "./Navadmin";
import Url from "../../Url";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMemo } from "react";

toast.configure()

//import { set } from "react-ga";

const Empreglist = () => {
  const url1 = Url();
  const url = url1["url"];

  const [getempreglist, setGetempreglist] = useState([]);
  const [deletescuucess, setDeletescuucess] = useState(false);

  const [searchcontent, setSearchcontent] = useState("");
  const deleteEmployer = (id) => {
    const res = window.confirm("The Employer will be deleted, are you sure?");
    if (res) {
      const api = url + "deleteEmployerById/" + id;
      fetch(api, {
        method: "DELETE",
        body: JSON.stringify({ id }),
        headers: { "Content-Type": "application/json" },
      })
        .then((apidata) => {
          return apidata.json();
        })
        .then((actualdata) => {
          toast.success("Employer deleted successfully ....", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
          setDeletescuucess(true);
        });
    }
  };

  const employerActivate = (id) => {
    const res = window.confirm("This intern account will be activated, are you sure?");
    if (res) {
      const api = url + "activateEmployer";
      fetch(api, {
        method: "PUT",
        body: JSON.stringify({ id }),
        headers: { "Content-Type": "application/json" },
      })
        .then((apidata) => {
          return apidata.json();
        })
        .then((actualdata) => {
          toast.success("Intern account activated successfully ....", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
          setDeletescuucess(true);
        });
    }
  }

  const employerDeactivate = (id) => {
    const res = window.confirm("This intern account will be deactivated, are you sure?");
    if (res) {
      const api = url + "deActivateEmployer";
      fetch(api, {
        method: "PUT",
        body: JSON.stringify({ id }),
        headers: { "Content-Type": "application/json" },
      })
        .then((apidata) => {
          return apidata.json();
        })
        .then((actualdata) => {
          toast.success("Intern account deactivated successfully ....", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
          setDeletescuucess(true);
        });
    }
  }


  useEffect(() => {
    const api = url + "GetAllEmployerRegDetails";
    fetch(api, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        //console.log(actualdata1)
        setGetempreglist(actualdata1);
        setDeletescuucess(false); // this line
      });
  }, [deletescuucess]);

  useEffect(() => {
    const api = url + "GetAllEmployerRegDetails";
    fetch(api, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        //console.log(actualdata1)
        setGetempreglist(actualdata1);
      });
  }, []);

  /*--------------------------- Generate CSV ---------------------------*/
  const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    var CSV = "";

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ",";
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + "\r\n";
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + "\r\n";
    }

    if (CSV === "") {
      alert("Invalid data");
      return;
    }

    //Generate a file name
    var fileName = "IG Employer List";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  /*--------------------------- Generate CSV ---------------------------*/

  //new code
    const columns = useMemo(
    () => [
        { accessor: 'id', Header: 'Serial No' },
        { accessor: 'fullname', Header: 'Employee Name' },
        { accessor: 'email', Header: 'Email Id', id: "email" },
        { accessor: 'password', Header: 'Password' },
        { accessor: 'org_name', Header: 'Org Name' },
        { accessor: 'unable_login', Header: 'Status', id: "status", 
        Cell: ({value}) => {
            return(
                <p>
                    {value === 1 ? "Active" : "Inactive"}
                </p>
            )
        } 
        },
        { accessor: 'mobileno', Header: 'Mobile No' },
        { accessor: 'email', Header: 'View', id: "view",
        Cell : ({value}) => {
            return (
                <Link to={"Empintpost/" + value} target="_blank">
                    Click here
                </Link>
            )
        } 
        },
        { accessor: 'created_at', Header: 'Registration Date' },
        { accessor: 'total_internships', Header: 'No of Internship Posted' },
        { accessor: 'unable_login', Header: 'Status Action', id: "status_action",
            Cell: ({value, row}) => {
                return(
                <div>
                    {value === 1 && <Button className="btn-sm" variant="warning" onClick={() => employerDeactivate(row.original.id)}>Deactivate</Button>}
                    {value === 0 && <Button className="btn-sm btn-success" onClick={() => employerActivate(row.original.id)}>Activate</Button>}
                </div>
                )
            }
        },
        { accessor: 'action', Header: 'Action',
            Cell: ({value, row}) => {
                return(
                    <Button className="btn-sm btn-outline-danger btn-white m-1" onClick={() => deleteEmployer(row.original.id)}>
                        Delete
                    </Button>
                )
            }
        },
    ],
    []
    );
    // console.log(getempreglist)
    const data = useMemo(() => getempreglist, [getempreglist]);

    const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 30,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((columns) => [
				...columns
			]);
		}
	);

    const { pageIndex, globalFilter } = state;

  return (
    <Fragment>
    <Row>
      <Col lg={12} md={12} sm={12}>
        <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
          <div className="mb-3 mb-md-0">
            <h1 className="mb-1 h2 fw-bold">Registered Employee List</h1>
            <Breadcrumb>
              <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item href="#">Registration</Breadcrumb.Item>
              <Breadcrumb.Item active>Employee</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
          </div>
        </div>
      </Col>
    </Row>
    <Row>
    <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-3 px-3 ">
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder="Search"
            />
    </Col>
    {getempreglist.length > 0 ? (
          <>
            <Col sm={12} className="ml-0">
              <button className="btn btn-success ml-2 mt-1" onClick={() => JSONToCSVConvertor(getempreglist, "_Employee_details", true)}>
                Download Excel
              </button>
            </Col>
          </>
        ) : null}
    </Row>

    <Row className="mt-3">
      <Col lg={12} md={12} sm={12}>
        <Card>
          <Card.Body className="p-0">
            <div className="table-responsive" style={{
              overflow: "auto",
              // height:"500px"
              }}>
              <Table {...getTableProps()} className="text-nowrap" style={{height:"400px"}}>
                <thead className="table-light">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>

            {/* Pagination @ Footer */}
            <Pagination
              previousPage={previousPage}
              pageCount={pageCount}
              pageIndex={pageIndex}
              gotoPage={gotoPage}
              nextPage={nextPage}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Fragment>
  );
};

export default Empreglist;
