// import node module libraries
import { Row, Col, Container, Button, Image } from "react-bootstrap";
import { Fragment, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
// import sub components
// import JobListingListviewCard from "components/marketing/common/cards/JobListingListviewCard";
import JobsListView from "components/IntListing/JobsListView";
import likeclicked from "assets/images/job/likeclicked.svg";
import like from "assets/images/job/liked.svg";
// import tippy tooltip
import Tippy from "@tippyjs/react";
import "tippy.js/animations/scale.css";

import Url from "components/Url";
import { act } from "react-dom/test-utils";
import { ToastContainer, toast } from "react-toastify";
import LoginModalNew from 'components/authentication/LoginModalNew';
import {getTimePassed} from "../../helper/utils.js";

const JobSingle = (props) => {
	const [job, setJob] = useState(null);
	const [fav, setFav] = useState(new Set());
	const [similarJobs, setSimilarJobs] = useState([]);
	const stutoken = localStorage.getItem("stutoken");
	const email = localStorage.getItem("email");
	const studentId = localStorage.getItem("studentId");

	const history = useHistory();

	const [loginModalShow, setLoginModalShow] = useState(false);

	const url1 = Url();
	const url = url1["url"];
	const s3 = url1["s3"];
	const [s3url, setS3url] = useState(s3 + "imgupload/");
	const id = props.match.params.slug;

	const Apply = (internshipId) => {
		if (stutoken) {
			var intern_email = email;
			var studentId = localStorage.getItem("studentId");
			const api = url + "applyForInternship";
			fetch(api, {
				method: "POST",
				body: JSON.stringify({ internshipId, studentId }),
				headers: { "Content-Type": "application/json" },
			})
				.then((apidata) => {
					return apidata.json();
				})
				.then((actualdata) => {
					if (actualdata["message"] === "No_software_skill") {
						// setStusftskill(true);
						toast.warning("No software skill. Please add");
					}
					if (actualdata["message"] === "Already_applied") {
						toast.warning("Already Applied");
						return;
					}
					if (actualdata["message"] === "student_internship created") {
						// setApplysuccessmodal(true);
						toast.success("You have successfully applied to this internship");
					}
				});
		} else {
			toast.error("To apply for an internship, please login first");
			localStorage.setItem("internshipIdApplication", id);
			//window.location.href = "/student-login";
			setLoginModalShow(true);
		}
	};
	useEffect(() => {
		let internshipId = localStorage.getItem("internshipIdApplication");
		if (internshipId) {
			Apply(internshipId);
			localStorage.removeItem("internshipIdApplication");
		}
		const api1 = url + "getEmployerInternshipDetailsById/" + id;
		fetch(api1, {
			method: "GET",
		})
			.then((apidata1) => {
				return apidata1.json();
			})
			.then((actualdata1) => {
				console.log("detail", actualdata1);
				if (actualdata1.length > 0) {
					// setJob(actualdata1[0]);
					setJob(actualdata1[0]);
					console.log("data", actualdata1);
					const api2 =
						url +
						"getSimilarInternships/" +
						actualdata1[0].internship_details +
						"/" +
						+actualdata1[0].id;
					fetch(api2, {
						method: "GET",
					})
						.then((apidata1) => {
							return apidata1.json();
						})
						.then((actualdata1) => {
							// console.log("similar", actualdata1);
							if (actualdata1.length <= 4) setSimilarJobs(actualdata1);
							else setSimilarJobs(actualdata1.slice(0, 4));
						});
				}
			});
	}, []);
	// console.log("jobs", job);
	const Favorite = (employerInternshipId) => {
		if (!studentId) {
			toast.error("Please log in first");
			return;
		}
		const api = url + "addRemoveFavoriteInternship";

		fetch(api, {
			method: "POST",
			body: JSON.stringify({ employerInternshipId, studentId }),
			headers: { "Content-Type": "application/json" },
		})
			.then((apidata) => {
				return apidata.json();
			})
			.then((actualdata) => {
				if (actualdata["favorite_ind"] == 1) {
					let st = new Set();
					fav.forEach((ele) => st.add(ele));
					st.add(employerInternshipId);
					setFav(st);
					toast.success("Added to Favorites");
				}
				if (actualdata["favorite_ind"] == 0) {
					console.log("already");
					let st = new Set();
					fav.forEach((ele) => {
						if (employerInternshipId != ele) st.add(ele);
					});

					setFav(st);
					toast.warning("Removed from Favorites");
				}
			});
	};
	// get the favourite post details
	useEffect(() => {
		const controller = "getStudentFavoriteInternships/" + studentId;
		const api = url + controller;
		fetch(api, {
			method: "GET",
		})
			.then((apidata1) => {
				return apidata1.json();
			})
			.then((actualdata1) => {
				console.log("inside fav", actualdata1);
				let favStore = new Set();
				actualdata1.forEach((ele) => {
					favStore.add(ele);
				});
				console.log(favStore, "store");
				setFav(favStore);
			});
	}, []);
	return (
		<>
		<ToastContainer />
		<main>
			<LoginModalNew show={loginModalShow} setModalShow={setLoginModalShow} onHide={() => setLoginModalShow(false)} activeTab={"student"} />
			{!job ? (
				"Not found"
			) : (
				<section className="py-14">
					<Container>
						<Row>
							<Col xl={{ span: 8, offset: 2 }} md={12} className="p-2">
								<button className="btn btn-link" onClick={() => {(history.length > 1) ? history.goBack() : "/Intlisting"}}>{"<< Back"}</button>
							</Col>

							<Col xl={{ span: 8, offset: 2 }} md={12}>
								<div className="d-xl-flex ">
									<div className="mb-3 mb-md-0 col-1">
										<Image
											src={s3url + job.org_logo}
											alt=""
											className="icon-shape border rounded-circle"
											style={{
												height: "56px",
												width: "56px",
											}}
										/>
									</div>
									{/* text */}
									<div
										className="ms-xl-3   mt-3 mt-xl-0 col-11"
										style={{ overflowWrap: "anywhere" }}
									>
										<div
											className="d-flex justify-content-between mb-5"
											style={{ marginRight: "25px" }}
										>
											<div>
												<h1 className="mb-1 h2 ">{job.internship_details}</h1>
												<div>
													<span>at {job.org_name} </span>
												</div>
											</div>
											<div>
												{fav.has(job.id) ? (
													<img
														src={likeclicked}
														alt=" "
														style={{ cursor: "pointer" }}
														onClick={() => {
															Favorite(job.id);
														}}
													/>
												) : (
													<img
														src={like}
														alt=" "
														style={{ cursor: "pointer" }}
														onClick={() => {
															Favorite(job.id);
														}}
													/>
												)}
											</div>
										</div>
										<div className="row">
											{/* year */}
											<div className="col-12">
											<div className="col-12">
													<i className="fe fe-briefcase text-muted"></i>
													<span className="ms-1 text-truncate">
													<span className="text-dark">Duration: </span>
														{job.duration1 +
															(job.duration2 == "M" ? " Months" : " Weeks")}
													</span>
												</div>
												<div className="col-12">
													<span className="text-muted" style={{padding:"3px"}}>&#8377;</span>
													<span className="ms-1 text-truncate">
													<span className="text-dark">Stipend: </span>
														{job.compensation1
															? job.compensation1 + " per month"
															: "Not disclosed"}
													</span>
												</div>
												<div className="col-12">
													<i className="fe fe-map-pin text-muted"></i>
													<span className="ms-1 ">
													<span className="text-dark">Location:</span> {job.location.replaceAll(',',', ')}
													</span>
												</div>
												<div className="col-12">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="currentColor"
														className="bi bi-tools"
														viewBox="0 0 16 16"
													>
														<path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
													</svg>
													<span className="ms-1 "><span className="text-dark">Skills:</span> {job.skill.replaceAll(',',', ')}</span>
												</div>
												<div className="col-12">
													<i className="fe fe-clock text-muted"></i>
													<span>
														{" "}
														<span className="text-dark">Posted at: </span>{getTimePassed(job.posting_date)}
													</span>
												</div>
												<div className="col-12">
													<i className="fe fe-clock text-muted"></i>
													<span> <span className="text-dark">Starts in: </span> Start Immediately</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<hr className="my-4" />
								<div>
									<p>
										<span>
											Job Applicants:{" "}
											<span className="text-dark">
												{!job.jobApplicants
													? "Not disclosed"
													: job.jobApplicants}
											</span>
										</span>
									</p>
								</div>
								{/* <div
									dangerouslySetInnerHTML={{
										__html: job.jobDetails,
									}}
									/> */}
								<div className="mt-6">
									<h2 className="mb-3 fs-3">Organization description</h2>
									<p>{job.org_description}</p>
								</div>
								<div className="mt-6">
									<h2 className="mb-3 fs-3">Job description</h2>
									<p dangerouslySetInnerHTML={{ __html: job.job_description }}></p>
									
								</div>
								<div className="mt-6">
									<h2 className="mb-3 fs-3">Skills</h2>
									<p>{job.skill}</p>
								</div>

								<div className="mt-6">
									<h2 className="mb-3 fs-3">Perks and Benefits</h2>
									{job.perks}
								</div>

								<div className="mt-5 d-grid">
									<Button
										// as="a"
										variant="primary"
										// href="#"
										onClick={(e) => {
											e.preventDefault();
											Apply(id);
										}}
									>
										Apply for this Job
									</Button>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xl={{ span: 8, offset: 2 }} md={12}>
								<div className="mt-12">
									<h2 className="mb-4">Similar Jobs</h2>

									<JobsListView postrec={similarJobs} />
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			)}
		</main>
		</>
	);
};

export default JobSingle;
