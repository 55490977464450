import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import { Accordion, Col, Row } from "react-bootstrap";
import moment from "moment";
import { FiChevronDown, FiPlus, FiTrash2 } from "react-icons/fi";
import { BiArrowBack } from "react-icons/bi";
import {BsRobot } from "react-icons/bs";

import Switch from "@material-ui/core/Switch";
import { InputField, Label, Button, RTE } from "../../foundation";
import { Controller } from "react-hook-form";
import Api, { ENDPOINTS } from "../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Axios from "axios";
import EmploymentHistoryAICard from "../EmploymentHistoryAICard";


const EmploymentHistory = ({
  watch,
  register,
  fields,
  append,
  remove,
  control,
  showEmployerSuggetion,
  setShowEmployerSuggetion,
  employmentSummaryDescription,
  handleCellClick
}) => {

  const [deleting, setDeleting] = useState(null);
  const employmentHistory = watch("employmentHistory");
  console.log(employmentHistory);

  const [masterData, setMasterData] = useState({});
  const { locations = [] } = masterData;
  const [suggestions, setSuggestions] = useState([]);
  
  const studentId = localStorage.getItem("studentId");
  const quillRef = useRef([]);
  const [idx, setIdx] = useState([])
  //const {showEmployerSuggetion, setShowEmployerSuggetion} = props;

  const fetchData = async () => {
    try {
      const [locations] = await Axios.all([
        Api.get(ENDPOINTS.GET_ALL_LOCATIONS),
      ]);
      const data = {
        locations: locations.data.map(({ placename }) => ({
          label: placename,
          value: placename,
        })),
      };
      // console.log(`api data : ${data}`);
      setMasterData(data);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);


  /**** CHANGE / AI Suggestions - Start */

  /**** CHANGE / AI Suggestions - End */

  useEffect(() => {
    if(quillRef && employmentSummaryDescription){
      console.log(employmentSummaryDescription);
      console.log(quillRef);
      let existingContent = quillRef.current[idx].getEditor().root.innerHTML;
      let indexPosition = existingContent.length-4;
      let newContent = existingContent.replace(/[\r\n]/g, '') + " " + employmentSummaryDescription.trim();

      newContent = existingContent.slice(0, indexPosition)
      + " " + employmentSummaryDescription + existingContent.slice(indexPosition);
      console.log(newContent);
      if (quillRef.current) {

        // setResumeDetail({ ...resumeDetail, employmentSummary: {id:resumeDetail.employmentSummary.id, resume_id: resumeDetail.employmentSummary.resume_id, Description: newContent} });
        // setValue("employmentSummary.Description",newContent);
        
        console.log("in here..........................")
        console.log(newContent);
        const quill = quillRef.current[idx].getEditor();
        quill.root.innerHTML = newContent;
      }
    }
  }, [employmentSummaryDescription]); 

  const handleShowEmployerSuggestions = (index) => {
    console.log(index)
  }
  console.log(showEmployerSuggetion);
  const scrollToIndex = (index) => {
    setIdx(index)
    // const section = document.querySelector( `#employmentHistory${index}_section` );
    // section.scrollIntoView( { behavior: 'smooth', block: 'start' } );    
  };



  return (
    <div className="resume-builder__section" >
      <h2>Employment History</h2>
      <Label as="p">Show your relevant experience.</Label>
      {fields.map((field, index) => {
        const { Employer, Job_title, start_date, end_date } =
          employmentHistory?.[index] || {};
        const displayDate = [];
        if (start_date) displayDate.push(moment(start_date).format("MMM YYYY"));
        if (end_date) displayDate.push(moment(end_date).format("MMM YYYY"));
        return (
          <div key={field.key} className={cx("resume-builder__section__card p-0")} id={`employmentHistory${index}_section`}>
            <input
              type="hidden"
              id={`employmentHistory[${index}].id`}
              name={`employmentHistory[${index}].id`}
              defaultValue={field.id}
              // ref={register}
              {...register(`employmentHistory[${index}].id`)}
            />
            <Accordion defaultActiveKey={`${field.id ? "" : field.key}`}>
              <Accordion.Item
                as={"div"}
                variant="link"
                eventKey={`${field.key}`}
                className="resume-builder__section__card--title"
              >
                <Accordion.Header className="w-100">
                  <div className="m-0">
                    <p className="m-0">
                      {Employer && Job_title
                        ? `${Job_title} at ${Employer}`
                        : Job_title || Employer || "(Not Specified)"}
                    </p>
                    {displayDate.length > 0 && (
                      <Label className="mb-0">{displayDate.join(" - ")}</Label>
                    )}
                  </div>{" "}
                  {/* <span className="d-flex m-0">
										<FiChevronDown size={20} />
									</span> */}
                </Accordion.Header>

                <Accordion.Body eventKey={`${field.key}`}>
                  <>
                    <Row>
                      <Col>
                        {/* <Controller
												control={control}
												name={`employmentHistory[${index}].end_date`}
												render={({ onChange, value, name, ref }) => (
													<>
														<Switch
															checked={value === null}
															onChange={(e) =>
																onChange(e.target.checked ? null : "")
															}
															color="primary"
														/>{" "}
														<Label as="span">Currently work here</Label>
													</>
												)}
											/> */}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Label>Employer</Label>
                        <Controller
                          control={control}
                          name={`employmentHistory[${index}].Employer`}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => {
                            return (
                              <InputField
                                stretch
                                placeholder="Enter Employer Name"
                                // {...controlProps}
                                onBlur={onBlur} // notify when input is touched
                                onChange={onChange} // send value to hook form
                                checked={value}
                                inputRef={ref}
                              />
                            );
                          }}
                        />
                      </Col>
                      <Col>
                        <Label>Job Title</Label>
                        <Controller
                          control={control}
                          name={`employmentHistory[${index}].Job_title`}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <InputField
                              stretch
                              placeholder="Enter Job Title"
                              onBlur={onBlur} // notify when input is touched
                              onChange={onChange} // send value to hook form
                              checked={value}
                              inputRef={ref}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Row>
                          <Col xs={6} className="pr-1">
                            <Label>Start Date</Label>
                            <Controller
                              control={control}
                              name={`employmentHistory[${index}].start_date`}
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: {
                                  invalid,
                                  isTouched,
                                  isDirty,
                                  error,
                                },
                                formState,
                              }) => (
                                <InputField
                                  type="date"
                                  stretch
                                  onBlur={onBlur} // notify when input is touched
                                  onChange={onChange} // send value to hook form
                                  checked={value}
                                  inputRef={ref}
                                />
                              )}
                            />
                          </Col>
                          <Col xs={6} className="pl-1">
                            <Label>End Date</Label>
                            <Controller
                              control={control}
                              name={`employmentHistory[${index}].end_date`}
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: {
                                  invalid,
                                  isTouched,
                                  isDirty,
                                  error,
                                },
                                formState,
                              }) =>
                                end_date === null ? (
                                  <InputField
                                    stretch
                                    value="Present"
                                    readOnly
                                  />
                                ) : (
                                  <InputField
                                    type="date"
                                    min={start_date}
                                    stretch
                                    onBlur={onBlur} // notify when input is touched
                                    onChange={onChange} // send value to hook form
                                    checked={value}
                                    inputRef={ref}
                                  />
                                )
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Label>City</Label>
                        <Controller
                          control={control}
                          name={`employmentHistory[${index}].city`}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <InputField
                              stretch
                              placeholder="Enter city"
                              onBlur={onBlur} // notify when input is touched
                              onChange={onChange} // send value to hook form
                              checked={value}
                              inputRef={ref}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>Description
                        . Get <Button variant="link" onClick={ (e) => {setShowEmployerSuggetion(false); scrollToIndex(index); setShowEmployerSuggetion(true); handleCellClick(e)}} style={{cursor:"pointer", color:"var(--geeks-primary)"}} ><span style={{fontWeight:"bold"}}>Suggestions by IGBot</span> <BsRobot className="mb-2" size={20} /> </Button>
                            {/*<Button><BsRobot size={20} onClick={getSuggestions} style={{cursor:"pointer"}} /></Button>*/}
                            {/* The AICard is place here just to position it relative to the label */}
                          

                        </Label>
                        <Controller
                          control={control}
                          name={`employmentHistory[${index}].Description`} 
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <RTE
                              value={value}
                              onBlur={onBlur} // notify when input is touched
                              onChange={onChange} // send value to hook form
                              checked={value}
                              inputRef={ref}
                              ref={element => quillRef.current[index] = element}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </>
                </Accordion.Body>
              </Accordion.Item>
              {/* //Item */}

              {/* <Accordion.Body eventKey={`${field.key}`}>
								<>
									<Row>
										<Col>
											
										</Col>
									</Row>
									<Row className="mb-3">
										<Col>
											<Label>Employer</Label>
											<Controller
												control={control}
												name={`employmentHistory[${index}].Employer`}
												render={(controlProps) => (
													<InputField
														stretch
														placeholder="Enter Employer Name"
														{...controlProps}
													/>
												)}
											/>
										</Col>
										<Col>
											<Label>Job Title</Label>
											<Controller
												control={control}
												name={`employmentHistory[${index}].Job_title`}
												render={(controlProps) => (
													<InputField
														stretch
														placeholder="Enter Job Title"
														{...controlProps}
													/>
												)}
											/>
										</Col>
									</Row>
									<Row className="mb-3">
										<Col>
											<Row>
												<Col xs={6} className="pr-1">
													<Label>Start Date</Label>
													<Controller
														control={control}
														name={`employmentHistory[${index}].start_date`}
														render={(controlProps) => (
															<InputField
																type="date"
																stretch
																{...controlProps}
															/>
														)}
													/>
												</Col>
												<Col xs={6} className="pl-1">
													<Label>End Date</Label>
													<Controller
														control={control}
														name={`employmentHistory[${index}].end_date`}
														render={(controlProps) =>
															end_date === null ? (
																<InputField stretch value="Present" readOnly />
															) : (
																<InputField
																	type="date"
																	min={start_date}
																	stretch
																	{...controlProps}
																/>
															)
														}
													/>
												</Col>
											</Row>
										</Col>
										<Col>
											<Label>City</Label>
											<Controller
												control={control}
												name={`employmentHistory[${index}].city`}
												render={(controlProps) => (
													<InputField
														stretch
														placeholder="Enter city"
														{...controlProps}
													/>
												)}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<Label>Description</Label>
											<Controller
												control={control}
												name={`employmentHistory[${index}].Description`}
												render={({ onChange, value, name, ref }) => (
													<RTE value={value} onChange={onChange} />
												)}
											/>
										</Col>
									</Row>
								</>
							</Accordion.Body> */}
              {/* //Body */}
            </Accordion>
            <Button
              variant="link"
              disabled={deleting === index}
              className="resume-builder__section__card--delete"
              onClick={async () => {
                try {
                  setDeleting(index);
                  if (field.id) {
                    await Api.delete(
                      `${ENDPOINTS.DELETE_EXP_DETAIL}/${field.id}`
                    );
                  }
                  remove(index);
                } finally {
                  setDeleting(null);
                }
              }}
            >
              {deleting === index ? (
                <CircularProgress size={20} color="primary" />
              ) : (
                <FiTrash2 size={20} />
              )}
            </Button>
          </div>
        );
      })}
      <Row className="mb-3">
        <Col>
          <Button
            className="text-info fs-4"
            variant="link"
            type="button"
            onClick={() => {setShowEmployerSuggetion(false); append({})}}
          >
            <FiPlus />
            {fields.length < 1 ? " Add employment" : " Add one more employment"}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default EmploymentHistory;
