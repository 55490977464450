import React, { useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import moment from "moment";
import { FiChevronDown, FiPlus, FiTrash2 } from "react-icons/fi";
import { InputField, Label, Button, RTE } from "../../foundation";
import { Controller } from "react-hook-form";
import Switch from "@material-ui/core/Switch";
import Api, { ENDPOINTS } from "../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slider from "@material-ui/core/Slider";

const LANGUAGES_MAP = [
  "Native speaker",
  "Highly proficient",
  "Very good command",
  "Working knowledge",
  "Expert",
];
const Language = ({ watch, register, fields, append, remove, control }) => {
  const [deleting, setDeleting] = useState(null);
  const languages = watch("languages");
  return (
    <div className="resume-builder__section">
      <h2>Languages</h2>
      {fields.map((field, index) => {
        const { language_name, level } = languages?.[index] || {};
        return (
          <div key={field.key} className="resume-builder__section__card p-0">
            <input
              type="hidden"
              id={`languages[${index}].id`}
              name={`languages[${index}].id`}
              defaultValue={field.id}
              {...register(`languages[${index}].id`)}
            />
            <Accordion defaultActiveKey={`${field.id ? "" : field.key}`}>
              <Accordion.Item
                as={"div"}
                variant="link"
                eventKey={`${field.key}`}
                className="resume-builder__section__card--title"
              >
                <Accordion.Header className="w-100">
                  <div className="m-0">
                    <p className="m-0">{language_name || "(Not Specified)"}</p>
                    <Label className="mb-0">{LANGUAGES_MAP[level] || ""}</Label>
                  </div>{" "}
                  {/* <span className="d-flex m-0">
                    <FiChevronDown size={20} />
                  </span> */}
                </Accordion.Header>
                <Accordion.Body eventKey={`${field.key}`}>
                  <>
                    <Row className="mb-3">
                      <Col>
                        <Label>Language</Label>
                        <Controller
                          control={control}
                          name={`languages[${index}].language_name`}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <InputField
                              stretch
                              placeholder="Enter name of language"
                              onBlur={onBlur} // notify when input is touched
                              onChange={onChange} // send value to hook form
                              checked={value}
                              inputRef={ref}
                            />
                          )}
                        />
                      </Col>
                      {/* <Col>
                        <Label>Level</Label>
                        <Controller
                          control={control}
                          name={`languages[${index}].level`}
                          render={({ 
                            
                            field: {onChange, value} }) => (
                            <Slider
                              min={0}
                              max={4}
                              value={value}
                              onChange={(e, val) => onChange(val)}
                              style={{ color: "#007bff" }}
                            />
                          )}
                        />
                      </Col> */}
                    </Row>
                  </>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Button
              variant="link"
              disabled={deleting === index}
              className="resume-builder__section__card--delete"
              onClick={async () => {
                try {
                  setDeleting(index);
                  if (field.id) {
                    await Api.delete(
                      `${ENDPOINTS.DELETE_LANGUAGE}/${field.id}`
                    );
                  }
                  remove(index);
                } finally {
                  setDeleting(null);
                }
              }}
            >
              {deleting === index ? (
                <CircularProgress size={20} color="primary" />
              ) : (
                <FiTrash2 size={20} />
              )}
            </Button>
          </div>
        );
      })}
      <Row className="mb-3">
        <Col>
          <Button
            className="text-info fs-4"
            variant="link"
            type="button"
            onClick={() => append({ level: 0 })}
          >
            <FiPlus />
            {fields.length < 1 ? " Add language" : " Add one more language"}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Language;
