// import node module libraries
import React, { Fragment, useState, useMemo, useEffect } from "react";
import { Link, useLocation  } from "react-router-dom";
import {
  Container,
  Modal,
  Row,
  Col,
  Badge,
  Dropdown,
  Table,
  Card,
  ListGroup,
  Button,
  Form
} from "react-bootstrap";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";

// import bootstrap / feather icons
import {
  FileEarmarkText,
  Trash3,
  FileArrowUp,
  XCircle,
  Calendar2Week,
} from "react-bootstrap-icons";
import { MoreVertical } from "react-feather";
import { AiOutlineStar } from "react-icons/ai";
import { BsChatDots } from "react-icons/bs";
import { GrDocumentUser } from "react-icons/gr";

// import custom components
import { toast } from "react-toastify";
//import RightIconInfoCard from 'components/common/cards/RightIconInfoCard';
import RightIconInfoCard from "./RightIconInfoCard";
import GlobalFilter from "components/common/advance-table/GlobalFilter";
import Pagination from "components/common/advance-table/Pagination";
import MentorshipList from "./MentorshipList";

// import data files
import InfoCardsData from "./data/InfoCardsData";
import InternshipListData from "./data/InternshipListData";
import Chat from "../employer/chat/Chat";
import Url from "../Url";
import Axios from "axios";
import NewAxios from "NewApi";
import CheckStudentProfileStatus from "./CheckStudentProfileStatus";
import "./dashboard.css";

const Dashboard = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const location = useLocation();

  const url1 = Url();
  const url = url1["url"];

  const studentName = localStorage.getItem("loginName");
  const applicantId = localStorage.getItem("applicantid");
  const studentId = localStorage.getItem("studentId");
  const token = localStorage.getItem("stutoken");

  const [studentbasic, setStudentbasic] = useState("");
  const [dashboardStats, setDashboardStats] = useState("");
  const [newChatMessage, setNewChatMessage] = useState(false);
  const [newChatCounter, setNewChatCounter] = useState(0);
  const [applicationList, setApplicationList] = useState([]);
  const [resumeSubscriptions, setResumeSubscriptions] = useState([]);
  const [mentorships, setMentorships] = useState();
  const [reloadMentorData,setReloadMentorData] = useState(false);
  const [viCourseList, setVICourseList] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [loadData, setLoadData] = useState(false);

  const [test, setTest] = useState("test");

  // used to show the related component 1=Internship list, 2=Mentorship list
  const [showTableSelection, setShowTableSelection ] = useState(1); 
  
  const [isChecked, setIsChecked] = useState(false);

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn-icon btn btn-ghost btn-sm"
    >
      {children}
    </Link>
  ));

  if (!localStorage.getItem("stutoken")) {
    window.location.href = "/login";
    return;
  }

  const getNewMsgCounter = () => {
    const api = url + "getNewChatCounterForStudent/" + studentId + "/S" + token;
    fetch(api, {
      method: "GET",
    })
      .then((apidata1) => {
        //console.log(apidata1)
        return apidata1.json();
      })
      .then((actualdata1) => {
        console.log(actualdata1);
        console.log(actualdata1.newChatMessageCount.totalUnreadCount);
        if (actualdata1.newChatMessageCount.totalUnreadCount > 0) {
          if (actualdata1.newChatMessageCount.totalUnreadCount) {
            console.log("setting true");
            setNewChatCounter(actualdata1.newChatMessageCount.totalUnreadCount);
            setNewChatMessage(true);
          } else {
            setNewChatCounter(0);
            setNewChatMessage(false);
          }
        } else {
          if (actualdata1.message) {
            if (actualdata1.message == "unauthorized") {
              localStorage.clear();
              sessionStorage.setItem("showTab", "student");
              window.location.href = "/login";
              // toast.warning("Session expired, please login again",
              //     {autoClose:"2000", onClose: () => {}}
              // );
            }
          }
        }
      });
  };

  useEffect(() => {
    getNewMsgCounter();
    var resumeSubscriptionVar = [];
    const resumeSubscriptionAPI = url + "getResumeSubscriptions/" + studentId;
    var status = 0;
    fetch(resumeSubscriptionAPI, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((apidata1) => {
        status = apidata1.status;
        return apidata1.json();
      })
      .then((actualdata1) => {
        console.log(actualdata1);
        if (status == 403) {
          setResumeSubscriptions([]);
          toast.warning(actualdata1.message);
        } else {
          setResumeSubscriptions(actualdata1.resumeSubscription);
          resumeSubscriptionVar = actualdata1.resumeSubscription;
        }
      });

    if (applicantId) {
      var api = url + "getApplicantProfileById/" + applicantId;
      fetch(api, {
        method: "GET",
      })
        .then((apidata1) => {
          return apidata1.json();
        })
        .then((actualdata1) => {
          if (actualdata1.applicant_basic_detail !== "not-found") {
            setStudentbasic(actualdata1.applicant_basic_detail);
            setIsChecked(actualdata1.applicant_basic_detail?.internship_interest_status === 1);
          }
          setVICourseList(
            actualdata1.virtualInternshipList !== "not-found"
              ? actualdata1.virtualInternshipList
              : []
          );
          setShowModal(true);
        });

      api = url + "getStudentDashboardStats/" + studentId;
      /* Umesh - end */

      fetch(api, {
        method: "GET",
      })
        .then((apidata1) => {
          return apidata1.json();
        })
        .then((actualdata1) => {
          setDashboardStats([
            {
              id: 1,
              icon: <FileEarmarkText />,
              text: actualdata1.total_application,
              subText: "All Applications",
              color: "secondary",
              link: "#",
              bg: "rgba(254,233,217, 0.8)",
            },
            {
              id: 2,
              icon: <FileEarmarkText />,
              text: actualdata1.shortlist,
              subText: "Shortlisted",
              color: "success",
              link: "#",
              bg: "rgba(215,240,229, 0.8)",
            },
            {
              id: 3,
              icon: <FileArrowUp />,
              text: actualdata1.offersent,
              subText: "Offer Received",
              color: "info",
              link: "#",
              bg: "rgba(204,226,233, 0.8)",
            },
            {
              id: 4,
              icon: <AiOutlineStar />,
              text: actualdata1.total_favorite,
              subText: "Favorites",
              color: "danger",
              link: "#",
              bg: "rgba(255,238,204, 0.8)",
            },
            {
              id: 5,
              icon: <GrDocumentUser />,
              text: "Resume Builder",
              subText:
                resumeSubscriptionVar?.length > 0
                  ? "Valid Till " + resumeSubscriptionVar[0].end_date
                  : "No Subscriptions",
              color: "danger",
              link: "/resumes",
              bg: "rgba(255,238,204, 0.8)",
            },
            {
              id: 6,
              icon: <GrDocumentUser />,
              text: "Mentorships",
              subText: "Mentorships",
              color: "danger",
              link: "#",
              bg: "rgba(255,238,204, 0.8)",
            },
            {
              id: 7,
              icon: <BsChatDots />,
              text: "Chat",
              subText: newChatCounter + " Unread messages",
              color: "primary",
              link: "#",
              modal: "#chatModal",
              bg: "rgba(204,226,233, 0.8)",
            },
          ]);
        });

      console.log("loading");
      (async () => {
        getApplicationList();
      })();
    }

    setLoadData(false);
  }, [loadData, newChatCounter]);

  useEffect(() => {
    if(reloadMentorData){
      console.log("get mentorship list");
      getMentorshipList();
      setReloadMentorData(false);
    }
  },[reloadMentorData]);

  useEffect(() => {
    // Check if the current URL hash includes '#mentorship'
    if (location.hash === '#mentorship') {
      handleMentorshipClick();
    }
  }, [location]); // Depend on location to re-run effect when it changes


  //get the Student Internship applications list for various status'
  const getApplicationList = (listId) => {
    let controller = "getStudentInternshipApplications/" + studentId;
    if (listId) {
      switch (listId) {
        case 2:
          controller = controller + "/1"; //shortlisted
          break;
        case 3:
          controller = controller + "/2"; //hired
          break;
        case 4:
          controller = controller + "/5"; //favorites
          break;
      }
    }

    const api = url + controller;
    fetch(api, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        //console.log(actualdata1);
        setApplicationList(actualdata1);
      });
  };
  const handleMentorshipClick = (e) => {
    // To prevent default link behavior when function is called manually
    if (e) e.preventDefault();
    
    getMentorshipList();
  }

  const getMentorshipList = async () => {

    let apiURL = url + "getMentorBookingsForUser" + "/" + studentId;

    try {
      const response = await Axios.get(apiURL, {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      console.log(response.data.mentorBookings);
      setMentorships(response.data.mentorBookings);
      setShowTableSelection(2); // Update UI after successful API call
    } catch (error) {
      var status = error.response.status;
      // Handle error if needed
    }
  };

  const redirectToLMS = () => {
    const token = localStorage.getItem("stutoken");
    const studentId = localStorage.getItem("studentId");

    const api = url + "verifyLMSUser";
    fetch(api, {
      method: "POST",
      body: JSON.stringify({ token, studentId }),
      headers: { "Content-Type": "application/json" },
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        //console.log(actualdata1.apiURL);

        if (actualdata1.apiURL !== "") {
          let a = document.createElement("a");
          a.target = "_blank";
          a.href = actualdata1.apiURL;
          a.click();
          //window.location.href = actualdata1.apiURL;
        } else {
          alert(
            "Your session has expired, please login again to access your courses"
          );
          return false;
        }
      });
  };

  const ActionMenu = (props) => {
    const { status } = props;
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-gray" />
        </Dropdown.Toggle>
        <Dropdown.Menu align={"end"}>
          {status === "Draft" && (
            <Fragment>
              <Dropdown.Item eventKey="1">
                <FileArrowUp size={15} className="dropdown-item-icon" /> Publish
              </Dropdown.Item>
            </Fragment>
          )}
          {(status === "Inactive" || status === "Draft") && (
            <Fragment>
              <Dropdown.Item eventKey="1">
                <FileEarmarkText size={15} className="dropdown-item-icon" />{" "}
                View Internship
              </Dropdown.Item>
              <Dropdown.Item eventKey="2">
                <Trash3 size={15} className="dropdown-item-icon" /> Delete
                Internship
              </Dropdown.Item>
            </Fragment>
          )}
          {status === "Active" && (
            <Fragment>
              <Dropdown.Item eventKey="1">
                <FileEarmarkText size={15} className="dropdown-item-icon" />{" "}
                View
              </Dropdown.Item>
              <Dropdown.Item eventKey="1">
                <XCircle size={15} className="dropdown-item-icon" /> Close
              </Dropdown.Item>
              <Dropdown.Item eventKey="1">
                <div classname="d-flex ">
                  <div>
                    <Calendar2Week size={15} className="dropdown-item-icon" />
                  </div>
                  <div classname="ms-2">
                    <div> Extend deadline by Month</div>
                    <small classname="text-muted">
                      (Expires on 2023-03-31)
                    </small>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item eventKey="1">
                <FileEarmarkText size={15} className="dropdown-item-icon" />{" "}
                Post Similar
              </Dropdown.Item>
              <Dropdown.Item eventKey="3">
                <Trash3 size={15} className="dropdown-item-icon" /> Delete
              </Dropdown.Item>
            </Fragment>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  // Column heading and cell format setup
  const columns = useMemo(
    () => [
      { accessor: "id", Header: "ID", show: false },
      { accessor: "internship_details", Header: "Internship Name" },
      { accessor: "org_name", Header: "Company Name" },
      { accessor: "created_at", Header: "Date" },
      {
        accessor: "application_status",
        Header: "Status",
        Cell: ({ value, row }) => {
          return (
            <Badge
              bg={
                value === "Not Shortlisted"
                  ? "warning"
                  : value === "Hired/Offer Sent"
                  ? "success"
                  : value === "Rejected"
                  ? "danger"
                  : value === "Shortlisted"
                  ? "info"
                  : null
              }
              className="text-white"
            >
              {value}{" "}
            </Badge>
          );
        },
      },
      /*
            {
                accessor: 'received', Header: 'Received',
                Cell: ({ value, row }) => {
                    return (
                        <Link to="/dashboard/applications/all"
                            className={`btn btn-${value === 'View Applications' ? 'light' : 'primary'} btn-sm`}>{value}</Link>
                    );
                }
            },
            {
                accessor: 'action',
                Header: '',
                Cell: ({ row }) => {
                    return <ActionMenu status={row.original.status} />;
                }
            }
            */
    ],
    []
  );

  const data = useMemo(() => applicationList, [applicationList]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { pageIndex, globalFilter } = state;

  const handleSwitchChange = async (e) => {
    var status = e.target.checked ? 1 : 0;
		setIsChecked(e.target.checked)
    try {
		  const response = await NewAxios.post("changeInternshipInterestStatus", 
        {status}
        ,{
			headers: {
			  "Content-type": "application/json",
			  Authorization: `Bearer ${token}`,
			  "X-USER-ID": studentId,
			},
		  });
	
        toast.success(response.data.message,{autoClose:1000});

      } catch (error) {
        toast.warning(response.data.message);
        //setLoading(false);
      }
	
		// window.location.href = `/lms/online-training/video/${topicId}/${encryptedCareerDetailId}`;
	  };

  return (
    <Fragment>
      {/* Welcome Section */}

      <CheckStudentProfileStatus />
      {console.log(viCourseList.length)}
      {viCourseList.length > 0 ? (
        <Modal
          //{...props}
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Enrolled Courses
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="px-0">
            <Card.Body className="p-0">
              <ListGroup variant="flush">
                {viCourseList.map((course, index) => {
                  return (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col className="d-flex align-items-center">
                          <Col xs={8}>
                            <i
                              className={
                                "fe fe-play-circle align-middle me-2 text-primary"
                              }
                            ></i>
                            <span className="h5 m-0">
                              {course.subject_name +
                                " (" +
                                course.course_duration_desc +
                                ")"}{" "}
                            </span>
                          </Col>
                          <Col xs={3}>
                            {course.course_status == "Expired" ? (
                              <Badge className="small" bg="danger">
                                Expired
                              </Badge>
                            ) : (
                              <Badge bg="success">{course.course_status}</Badge>
                            )}
                          </Col>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Card.Body>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center border-0 pt-0">
            {/*  Action Buttons  */}
            {/* <Button variant="primary" onClick={redirectToLMS}>
                Go to Virtual Internship
              </Button> */}
            {/*
              <a className="btn btn-outline-warning" href="/student-dashboard">
                Go to Student Dashboard
              </a>
              */}
            <Link className="btn btn-primary" to="/lms">
              Go to Virtual Internship
            </Link>
          </Modal.Footer>
        </Modal>
      ) : null}

      <section className=" pt-5 pb-6 ">
        <Container>
          <Row className="border-bottom pb-5 g-0 align-items-center">
            <Col md={5} xs={12}>
              <h1 className="mb-md-0">
                Hi, Welcome back!{" "}
                <span className="text-secondary">{studentbasic?.name}</span>
              </h1>
            </Col>
            <Col md={4} xs={12}>
              <Row className="align-items-left">
                <Col xs={12} className="form-check">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={isChecked}
                    onChange={handleSwitchChange}
                    className="h4 text-primary"
                    label="Actively Looking for an Internship?"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="12"><span className="small ms-3"><em>Check this box to highlight your profile to employers</em></span></Col> 
              </Row>            
            </Col>

            <Col xs={12} md={3} className="text-md-end mt-3">
              <Link to="/student-profile" className="btn btn-secondary mt-xs-2">
                View Profile
              </Link>
              {viCourseList.length > 0 && (
                <Link to="/lms" className="btn btn-primary ms-2">
                  Go to Virtual Internship
                </Link>
              )}
            </Col>
          </Row>
        </Container>
      </section>

      {/* Information cards section */}
      <section className="pb-4 ">
        <Container>
          <Row className="row-cols-xl-6 g-3">
            {dashboardStats.length > 0 &&
              dashboardStats
                .slice(0, dashboardStats.length - 1)
                .map((item, index) => {
                  return item.id == 6 ? (
                    <Col key={index}>
                      <Link
                        to={item.link}
                        className="text-reset"
                        onClick={
                          handleMentorshipClick
                        }
                      >
                        <RightIconInfoCard item={item} />
                      </Link>
                    </Col>
                  ) : (
                    <Col key={index}>
                      <Link
                        to={item.link}
                        className="text-reset"
                        onClick={() => {
                          getApplicationList(item.id);
                        }}
                      >
                        <RightIconInfoCard item={item} />
                      </Link>
                    </Col>
                  );
                })}
            {dashboardStats.length > 0 && (
              <Col>
                <Link to="#" className="text-reset" onClick={handleShow}>
                  <RightIconInfoCard
                    item={dashboardStats[dashboardStats.length - 1]}
                  />
                </Link>
              </Col>
            )}
          </Row>
        </Container>
      </section>

      {/* Internship Data List */}

      <section className="pb-14 bg-white">
        <Container>
        {showTableSelection == 1 ? 
        <div>
          
{/*           
          <Col xs={12} >
            <Row className="align-items-left">
              <Col xs={12} className="form-check ms-1">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  checked={isChecked}
                  onChange={handleSwitchChange}
                  className="h4 text-primary"
                  label="Actively Looking for an Internship?"
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12"><span className="small ms-1"><em>Check this box to highlight your profile to employers</em></span></Col> 
            </Row>
          </Col> */}
          
          <Row className="align-items-center mt-4">
            <Col md={8}>
              <div>
                <h2 className="mb-md-0 mb-3">Internship Application List </h2>
              </div>
            </Col>
            <Col md={4}>
              <div className="pt-2">
                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                  placeholder="Search internship post"
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-6">
            <Col lg={12}>
              <Table
                hover
                responsive
                {...getTableProps()}
                className="text-nowrap table-centered"
              >
                <thead className="table-light">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    //console.log(row)
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              {/* Pagination @ Footer */}
              <Pagination
                previousPage={previousPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
              />
            </Col>
          </Row>
          </div>
          :
          <MentorshipList mentorships={mentorships} setReloadMentorData={setReloadMentorData} />
          }
        </Container>
      </section>

      {/* Chat Modal Popup  */}
      <Modal
        backdrop="static"
        show={show}
        onHide={handleClose}
        scrollable
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="chatModalLabel">
            Messages from all internships & jobs
          </Modal.Title>
        </Modal.Header>
        <Chat />
      </Modal>
    </Fragment>
  );
};

export default Dashboard;
