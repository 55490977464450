import mentorMain from "../../assets/images/mentorLanding/mentorMain.png";
import mentor1 from "../../assets/images/mentorLanding/mentorOne.png";
import mentor2 from "../../assets/images/mentorLanding/mentorTwo.png";
import mentor3 from "../../assets/images/mentorLanding/mentorThree.png";
import mentorNumbers from "../../assets/images/mentorLanding/mentorNumbers.png";
import verifiedMentor from "../../assets/images/mentorLanding/verifiedMentors.png";

const MentorsImageSection = () => {
  return (
    <div className="w-100 h-100 position-relative">
      <div
        style={{
          backgroundColor: "#D6DCF94D",
          height: "20rem",
          width: "22rem",
          margin: "auto",
          borderRadius: "50%",
          border: "2px dotted #CBD5E1",
        }}
      >
        <div
          style={{
            backgroundColor: "#D6DCF9",
            height: "15rem",
            width: "17rem",
            position: "relative",
            top: "12%",
            left: "11%",
            borderRadius: "50%",
            border: "1px dashed #808DDF",
          }}
        >
          <div
            style={{
              backgroundColor: "#D6DCF9",
              height: "10rem",
              width: "12rem",
              position: "relative",
              top: "17%",
              left: "14%",
              borderRadius: "50%",
              border: "1px dashed #808DDF",
            }}
          ></div>
        </div>
        <img
          src={mentorMain}
          alt="mentorMain"
          style={{
            height: "20em",
            width: "26em",
            position: "relative",
            bottom: "63%",
            right: "2%",
          }}
        />
        <img
          src={verifiedMentor}
          alt="verifiedMentor"
          style={{
            position: "relative",
            bottom: "168%",
            left: "68%",
          }}
        />
        <img
          src={mentorNumbers}
          alt="mentorNumbers"
          style={{
            height: "3em",
            width: "6em",
            position: "relative",
            bottom: "104%",
            right: "50%",
          }}
        />
        <img
          src={mentor1}
          alt="mentorOne"
          style={{
            position: "relative",
            bottom: "86%",
            left: "8%",
          }}
        />
        <img
          src={mentor2}
          alt="mentorTwo"
          style={{
            height: "70px",
            width: "70px",
            position: "relative",
            bottom: "158%",
            left: "90%",
          }}
        />
        <img
          src={mentor3}
          alt="mentorThree"
          style={{
            height: "55px",
            width: "55px",
            position: "relative",
            bottom: "166%",
            right: "24%",
          }}
        />
      </div>
    </div>
  );
};

export default MentorsImageSection;
