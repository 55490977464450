import React, { useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
// import moment from "moment";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { InputField, Label, Button, RTE } from "../../foundation";
import { appendErrors, Controller } from "react-hook-form";
// import Switch from "@material-ui/core/Switch";
import Api, { ENDPOINTS } from "../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slider from "@material-ui/core/Slider";
import { Form } from 'react-bootstrap';

// const SKILLS_MAP = ["Novice", "Beginner", "Skillful", "Experienced", "Expert"];
const SKILLS_MAP = ["Novice","Beginner", "Intermediate", "Expert"];
const Skill = ({ watch, register, fields, append, remove, control }) => {
	const [deleting, setDeleting] = useState(null);
	const [cbutton, setCbutton] = useState(false);
	const skills = watch("skills");
	return (
		<div className="resume-builder__section">
			<h2>Skills</h2>
			<Label as="p">
				Choose important skills to show your talents! Make sure they match the
				keywords of the job listing if applying via an online system.
			</Label>
			{/* <Button
        onClick={
          () => {
            append({ skill_name: "C++"});
            setCbutton(true);
          }
          // is button pe click krke C++ box me aani chahiye
        }
      >
        C++
      </Button> */}
			{fields.map((field, index) => {
				const { skill_name, level } = skills?.[index] || {};
				// console.log(`Skill level = ${level}`);
				return (
					<div key={field.key} className="resume-builder__section__card p-0">
						<input
							type="hidden"
							id={`skills[${index}].id`}
							name={`skills[${index}].id`}
							defaultValue={field.id}
							{...register(`skills[${index}].id`)}
						/>
						<Accordion defaultActiveKey={`${field.id ? "" : field.key}`}>
							<Accordion.Item
								as={"div"}
								variant="link"
								eventKey={`${field.key}`}
								className="resume-builder__section__card--title"
							>
								<Accordion.Header className="w-100">
									<div className="m-0">
										<p className="m-0">{skill_name || "(Not Specified)"}</p>
										{/* <Label className="mb-0">{SKILLS_MAP[level] || ""}</Label> */}
									</div>{" "}
									{/* <span className="d-flex m-0">
										<FiChevronDown size={20} />
									</span> */}
								</Accordion.Header>
								<Accordion.Body eventKey={`${field.key}`}>
									<>
										<Row className="mb-3">
											<Col>
												<Label>Skill</Label>
												<Controller
													control={control}
													name={`skills[${index}].skill_name`}
													render={({
														field: { onChange, onBlur, value, name, ref },
														fieldState: { invalid, isTouched, isDirty, error },
														formState,
													}) => (
														<InputField
															stretch
															placeholder="Enter Skill Name"
															onBlur={onBlur} // notify when input is touched
															onChange={onChange} // send value to hook form
															checked={value}
															inputRef={ref}
														/>
													)}
												/>
											</Col>
											<Col>

												{/* <Label>Level</Label> */}
												<Label className="mb-0">{SKILLS_MAP[level] || ""}</Label>
												<Controller
													control={control}
													name={`skills[${index}].level`}
													render={({ 
														field: {onChange, value }}) => (
															<Slider
															min={1}
															max={3}
															value={value}
															onChange={(e, val) => onChange(val)}
															style={{ color: "#007bff" }}
														  />
													)}
												/>
											</Col>
										</Row>
									</>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
						<Button
							variant="link"
							disabled={deleting === index}
							className="resume-builder__section__card--delete"
							onClick={async () => {
								try {
									setDeleting(index);
									if (field.id) {
										await Api.delete(`${ENDPOINTS.DELETE_SKILL}/${field.id}`);
									}
									remove(index);
								} finally {
									setDeleting(null);
								}
							}}
						>
							{deleting === index ? (
								<CircularProgress size={20} color="primary" />
							) : (
								<FiTrash2 size={25} />
							)}
						</Button>
					</div>
				);
			})}
			<Row className="mb-3">
				<Col>
					<Button
					className="text-info fs-4"
						variant="link"
						type="button"
						onClick={() => append({ level: 1 })}
					>
						<FiPlus />
						{fields.length < 1 ? "Add Skill" : " Add one more Skill"}
					</Button>
				</Col>
			</Row>
		</div>
	);
};

export default Skill;
