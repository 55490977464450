// import node module libraries
import { Link, useHistory } from 'react-router-dom';
import {
    Image,
    Container,
    Row,
    Col,
    Card,
    Form,
    Button
} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
// import media files
import ImgStudentRegistration from 'assets/images/student-registration.png';
import GoogleAuthenticator from './GoogleAuthenticator';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Url from 'components/Url';

const StudentRegistration = () => {
    const url1 = Url();
    const url = url1.url
    const history = useHistory()

    const handleSubmit = (e) => {
        e.preventDefault();
        let stat 
        const fullname = e.target.name.value;
        const mobileno = e.target.number.value;
        const email = e.target.email.value;
        const password = e.target.password.value;
        const coupen_code = "NA";
    
        const api = url + "studentReg";
        fetch(api, {
          method: "POST",
          body: JSON.stringify({
            fullname,
            email,
            mobileno,
            password,
            coupen_code,
          }),
          headers: { "Content-Type": "application/json" },
        })
          .then((apidata) => {
            stat = apidata.status
            return apidata.json();
          })
          .then((actualdata) => {
            if(stat === 200 || stat === 201){
                toast.success("Email is sent, Please verify your account.");
                //Umesh - 20220607 - start
                //call zoho CRM API to add student lead
                const zohoLeadapi = url + "addStudentLead";
                fetch(zohoLeadapi, {
                    method: "POST",
                    body: JSON.stringify({ fullname, email, mobileno }),
                    headers: { "Content-Type": "application/json" },
                }).then((apidata) => {
                    console.log("Registered");
                });
                //Umesh - 20220607 - end                
                history.push('/');
            }
            console.log(actualdata);
            if (actualdata["message"] === "The email has already been taken.") {
              toast.warning("Email already exist");
              // toast.error('Email already exist', { position: toast.POSITION.TOP_CENTER })
              return;
            }
    
            if (actualdata["message"] === "invalid_coupen_code") {
                toast.warning("Invalid referral code, please enter correct referral code");
              // toast.error('Invalid referral code, please enter correct referral code', { position: toast.POSITION.TOP_CENTER })
              return;
            }
            //   setEnablelogin(true);
        });

      };

    return (
        <>
        <ToastContainer />
        <section className="py-8">
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="text-center">
                            <h1 className="display-5 fw-bold mb-2">Sign-up as a student and apply for <span className="text-secondary">free</span></h1>
                            <p className="lead fw-semibold">2,50,000+ companies hiring on Internshipgate</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-lg-8 mt-4 align-items-center">
                    <Col lg={{ order: 1, span: 6 }} xs={{ order: 2, span: 12 }} className="text-center text-lg-start">
                        <div>
                            <Image src={ImgStudentRegistration} alt="" className="w-100" />
                        </div>
                    </Col>
                    <Col xl={6} xs={12} lg={{ order: 2, span: 6 }}>
                        <div className="mx-xl-8 mb-8 mb-lg-0">
                            <Card className="shadow border-0">
                                <Card.Body className="p-6">
                                    <h2 className="mb-4">Create an account to continue</h2>
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group className="mb-3" controlId="student-full-name">
                                            <Form.Control type="text" name="name" placeholder="Full Name" required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="student-email">
                                            <Form.Control type="email" name="email" placeholder="Email Address" required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="student-mobile">
                                            <Form.Control type="number" name="number" placeholder="Mobile Number" required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="student-password">
                                            <Form.Control type="password" name="password" placeholder="Password" required />
                                        </Form.Group>
                                        {/* <Form.Check className="mb-4"
                                            type="checkbox"
                                            id="refferralCode"
                                            label=" I have a referral code"
                                        /> */}
                                        <div className="d-grid">
                                            <Button variant="primary" type="submit">Register</Button>
                                        </div>
                                    </Form>
                                    {/* <div className="mt-3">
                                        <span>Already registered? <Link to="#">Login</Link></span>
                                    </div> */}

   
                                    
                                </Card.Body >
                                <Card.Footer>
                                    <Col className="h5 text-center">Or One Click Sign-up with google id</Col>
                                    <Col xs={{span:8, offset:1}} sm={{span:10, offset:3}}>
                                        <GoogleOAuthProvider clientId="295493707204-t8uvccmg3mf93tvi75eh1s26kuso18je.apps.googleusercontent.com">
                                            <GoogleAuthenticator />
                                        </GoogleOAuthProvider>                                       
                                    </Col>
                                </Card.Footer>
                            </Card>

                          
                        </div>
                    </Col>
                </Row>

            </Container>
        </section>
        </>
    )
}

export default StudentRegistration